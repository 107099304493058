<template>
  <el-dialog
    v-bind="$attrs"
    :visible.sync="visible"
    title="服务器初始化配置"
    custom-class="ql-dialog"
    width="580px"
    :close-on-click-modal="false"
    @closed="resetForm"
  >
    <el-form :model="form" :rules="rules" ref="form" label-width="84px" size="small">
      <el-form-item label="学校编号" prop="schoolKey">
        <el-input v-model="form.schoolKey" :disabled="!isLocal"></el-input>
      </el-form-item>
      <el-form-item label="服务器IP" prop="host">
        <div class="form-width server-host">
          <div v-for="(item, index) in host" :key="index">
            <input type="text" v-model="host[index]" @blur="setHost" maxlength="3" :readonly="!isLocal" />
            <span v-if="index < host.length - 1" class="host-point"></span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="端口号" prop="port">
        <el-input v-model="form.port" :disabled="!isLocal"></el-input>
      </el-form-item>
      <el-form-item label="管理员" prop="adminName">
        <el-input v-model="form.adminName" :disabled="!isLocal"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="adminPhone">
        <el-input v-model="form.adminPhone" :disabled="!isLocal"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" :disabled="!isLocal" @click="handleSave" size="small">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
// components
import { Button, Dialog, Input, Form, FormItem } from 'element-ui';
// api
import { getInitSchoolServer, saveInitServer } from '@/api/back-stage';
// validate
import { checkPhone } from '@/utils/validate';

export default {
  name: 'initServer',
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  props: {
    show: Boolean,
  },
  data() {
    // 自定义手机验证
    const validatePhone = (rule, value, callback) => {
      let msg = checkPhone(value);
      if (msg !== true) {
        callback(new Error(msg));
        return;
      }
      callback();
    };

    return {
      isLocal: false, // 是否为本地校层（本地校层可以编辑， 非本地校层不能编辑）
      visible: false,
      form: {
        schoolKey: '',
        host: '',
        port: '',
        adminName: '',
        adminPhone: '',
      },
      host: Array.from({ length: 4 }),
      rules: {
        schoolKey: [{ required: true, message: '请填写学校编号', trigger: 'blur' }],
        host: [{ required: true, message: '请填写服务器IP', trigger: 'blur' }],
        port: [{ required: true, message: '请填写端口号', trigger: 'blur' }],
        adminName: [{ required: true, message: '请填写管理员', trigger: 'blur' }],
        adminPhone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
      },
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        this.visible = val;
        val && this.getInitSchoolServer();
      },
    },
  },
  methods: {
    // 获取服务器配置信息
    getInitSchoolServer() {
      getInitSchoolServer().then((res) => {
        let { schoolKey, host, port, adminName, adminPhone, curIsSingleSchool } = res.result;
        this.form = { schoolKey, host, port, adminName, adminPhone };

        let hostArr = host.split('.');
        this.host = Array.from({ length: 4 }, (v, i) => hostArr[i] || '');

        this.isLocal = Boolean(curIsSingleSchool == 1);
      });
    },
    // 处理IP地址
    setHost() {
      this.form.host = this.host.join('.');
    },
    // 保存
    handleSave() {
      if (!this.isLocal) return;
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveInitServer(this.form).then(() => {
            this.$message.success('保存成功！');
            this.visible = false;
            this.$emit('saveSuccess');
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置
    resetForm() {
      this.$refs.form.resetFields();
      for (let i in this.form) {
        this.form[i] = '';
      }
      this.$emit('update:show', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.server-host {
  display: flex;
  border: 1px solid #dee0e3;
  border-radius: 4px;

  input {
    width: 96px;
    text-align: center;
    outline: none;
  }

  .host-point {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #2c2f2e;
    vertical-align: middle;
  }
}

.form-width {
  width: 100%;
}
</style>
