// components
import { Button, Table, TableColumn, Dialog, Input, Form, FormItem, Select, Option } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
import searchInput from '@/components/searchInput';
import qlPagination from '@/components/qlPagination';
import areaSelect from '@/components/areaSelect';
import initServer from '../../components/initServer';

// api
import {
  getInitSchoolPage,
  resetUserPassword,
  getInitSchoolTypeList,
  editInitSchool,
  saveInitSchool,
} from '@/api/back-stage';

// utils
import { checkPhone } from '@/utils/validate';

export default {
  name: 'initSchool',
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    backStageHeader,
    searchInput,
    qlPagination,
    areaSelect,
    initServer,
  },
  data() {
    // 自定义手机号验证
    const validatePhone = (rule, value, callback) => {
      const checkMsg = checkPhone(value);
      if (checkMsg === true) {
        callback();
      } else {
        callback(new Error(checkMsg));
      }
    };

    return {
      schoolKeyword: '',

      // 表格
      listData: {
        list: [],
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
      listLoading: false,

      visible: false,
      loading: false,
      schoolTypeList: [], // 学校学段
      form: {
        id: '',
        schoolKey: '',
        schoolName: '',
        schoolType: '',
        adminName: '',
        adminPhone: '',
        areaCode: '',
        areaPath: '',
        areaName: '',
      },
      defaultCode: [],
      rules: {
        schoolKey: [{ required: true, message: '请填写学校编号', trigger: 'blur' }],
        schoolName: [{ required: true, message: '请填写学校名称', trigger: 'blur' }],
        schoolType: [{ required: true, message: '请填写学校学段', trigger: 'blur' }],
        adminName: [{ required: true, message: '请填写管理员', trigger: 'blur' }],
        adminPhone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        areaCode: [{ required: true, message: '请填写所属地区', trigger: 'change' }],
      },

      // 配置
      serverConfigVisible: false,
    };
  },
  methods: {
    // 按学校名称搜索
    searchData() {
      this.getDataList(true);
    },
    // 加载表格数据
    getDataList(reset = false) {
      this.listLoading = true;

      reset === true && (this.listData.pageIndex = 1);
      let { pageIndex, pageSize } = this.listData;

      getInitSchoolPage({
        pageIndex,
        pageSize,
        schoolNameFuzzy: this.schoolKeyword,
      })
        .then((res) => {
          if (res.result) {
            let { data, recordCount } = res.result.schoolPage;
            this.listData.list = data;
            this.listData.total = recordCount;
          } else {
            this.listData.list = [];
            this.listData.total = 0;
          }
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    // 获取学校学段列表
    getSchoolType() {
      getInitSchoolTypeList().then((res) => {
        this.schoolTypeList = res.result.schoolTypeList;
      });
    },
    // 获取当前学校详情
    getSchoolDetail(id) {
      this.loading = true;
      editInitSchool({ id })
        .then((res) => {
          let {
            id,
            schoolKey,
            schoolName,
            schoolType,
            areaCode,
            areaPath,
            areaName,
            countryCode,
            provinceCode,
            cityCode,
            admin: { userName, mobileNo },
          } = res.result.school;
          this.form = {
            id,
            schoolKey,
            schoolName,
            schoolType,
            areaCode,
            areaPath,
            areaName,
            adminName: userName,
            adminPhone: mobileNo,
          };
          this.defaultCode = [countryCode, provinceCode, cityCode, areaCode];
          this.schoolTypeList = res.result.schoolTypeList || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 切换条数
    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getDataList(true);
    },
    // 切换页码
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getDataList();
    },
    // 重置密码
    handleReset(row) {
      this.$msgbox
        .confirm('你确定重置管理员密码吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          resetUserPassword({
            userId: row.admin.userId,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '重置密码成功!',
            });
          });
        })
        .catch(() => {});
    },
    // 服务器配置
    serverSetting() {
      this.serverConfigVisible = true;
    },
    // 新增学校
    handleAdd() {
      this.visible = true;
      this.defaultCode = [];
      this.getSchoolType();
    },
    // 编辑
    handleEdit(row) {
      this.visible = true;
      this.getSchoolDetail(row.id);
    },
    // 获取地区信息
    getAreaInfo(area) {
      let areaName = area.map((item, index) => {
        return index === 0 ? item.shortAreaName : item.areaName;
      });
      this.form.areaName = `/${areaName.join('/')}/`;
      this.form.areaPath = area[area.length - 1].path;
      this.form.areaCode = area[area.length - 1].areaCode;
    },
    // 保存
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let { id, ...form } = this.form;
          let params = id ? this.form : form;
          saveInitSchool(params).then(() => {
            this.$message.success('保存成功！');
            this.visible = false;
            this.getDataList();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 表单重置
    resetForm() {
      this.$refs.form.resetFields();
      this.$refs.areaSelect.resetValue();
      for (let i in this.form) {
        this.form[i] = '';
      }
    },
  },
  mounted() {
    this.getDataList();
  },
};
